import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../layouts'

// import AboutSidenav from "../../partials/about-sidenav"

import '../scss/list.scss'

const Reports = ({ data }) => {
  return (
    <Layout>
      <div className="list-page annual-reports">

        <Helmet>
          <title>DigiWells – Annual reports</title>
          <meta property="og:title" content="DigiWells – Annual reports" />
          <meta name="description" content="DigiWells annual reports." />
          <meta property="og:description" content="DigiWells annual reports." />
          {
            /*
            <meta name="image" content={image} />
            <meta property="og:image" content={image} />
            */
          }
        </Helmet>

        <div className="container">
          <div className="container-7">
            <h1 className="page-title">Annual reports</h1>

            <div className="list">
            <a className="list-item" href="/reports/DigiWells_annual_report_2023_highres.pdf" target="_blank" rel="noreferrer">
  <div className="list-item-text">
    <h3 className="list-item-title">
      Annual Report 2023
    </h3>
    <div className="list-item-description">
      Download the high-resolution version
    </div>
  </div>
  <div className="list-item-icon">
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="30" fill="#222742" fillOpacity="0.1" />
      <path d="M39.333 34L37.453 32.12L31.333 38.2267L31.333 16.6667L28.6663 16.6667L28.6663 38.2267L22.5463 32.1067L20.6663 34L29.9997 43.3334L39.333 34Z" fill="#222742" />
    </svg>
  </div>
</a>
<a className="list-item" href="/reports/DigiWells_annual_report_2023_lowres.pdf" target="_blank" rel="noreferrer">
  <div className="list-item-text">
    <h3 className="list-item-title">
      Annual Report 2023 (low-resolution)
    </h3>
    <div className="list-item-description">
      Download the screen-quality version
    </div>
  </div>
  <div className="list-item-icon">
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="30" fill="#222742" fillOpacity="0.1" />
      <path d="M39.333 34L37.453 32.12L31.333 38.2267L31.333 16.6667L28.6663 16.6667L28.6663 38.2267L22.5463 32.1067L20.6663 34L29.9997 43.3334L39.333 34Z" fill="#222742" />
    </svg>
  </div>
</a>

            <a className="list-item" href="/reports/DigiWells_Anual_Report_2022.pdf" target="_blank" rel="noreferrer">
                <div className="list-item-text">
                  <h3 className="list-item-title">
                    Annual Report 2022
                  </h3>
                  <div className="list-item-description">
                    Download the high-resolution version
                  </div>
                </div>
                <div className="list-item-icon">
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="30" cy="30" r="30" fill="#222742" fillOpacity="0.1" />
                    <path d="M39.333 34L37.453 32.12L31.333 38.2267L31.333 16.6667L28.6663 16.6667L28.6663 38.2267L22.5463 32.1067L20.6663 34L29.9997 43.3334L39.333 34Z" fill="#222742" />
                  </svg>
                </div>
              </a>
              <a className="list-item" href="/reports/DigiWells_Anual_Report_2022_reduced_file_size.pdf" target="_blank" rel="noreferrer">
                <div className="list-item-text">
                  <h3 className="list-item-title">
                    Annual Report 2022 (low-resolution)
                  </h3>
                  <div className="list-item-description">
                    Download the screen-quality version
                  </div>
                </div>
                <div className="list-item-icon">
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="30" cy="30" r="30" fill="#222742" fillOpacity="0.1" />
                    <path d="M39.333 34L37.453 32.12L31.333 38.2267L31.333 16.6667L28.6663 16.6667L28.6663 38.2267L22.5463 32.1067L20.6663 34L29.9997 43.3334L39.333 34Z" fill="#222742" />
                  </svg>
                </div>
              </a>
              <a className="list-item" href="/reports/DigiWells_Anual_Report_2021.pdf" target="_blank" rel="noreferrer">
                <div className="list-item-text">
                  <h3 className="list-item-title">
                    Annual Report 2021
                  </h3>
                  <div className="list-item-description">
                    Download the high-resolution version
                  </div>
                </div>
                <div className="list-item-icon">
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="30" cy="30" r="30" fill="#222742" fillOpacity="0.1" />
                    <path d="M39.333 34L37.453 32.12L31.333 38.2267L31.333 16.6667L28.6663 16.6667L28.6663 38.2267L22.5463 32.1067L20.6663 34L29.9997 43.3334L39.333 34Z" fill="#222742" />
                  </svg>
                </div>
              </a>
              <a className="list-item" href="/reports/DigiWells_Anual_Report_2021_reduced_file_size.pdf" target="_blank" rel="noreferrer">
                <div className="list-item-text">
                  <h3 className="list-item-title">
                    Annual Report 2021 (low-resolution)
                  </h3>
                  <div className="list-item-description">
                    Download the screen-quality version
                  </div>
                </div>
                <div className="list-item-icon">
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="30" cy="30" r="30" fill="#222742" fillOpacity="0.1" />
                    <path d="M39.333 34L37.453 32.12L31.333 38.2267L31.333 16.6667L28.6663 16.6667L28.6663 38.2267L22.5463 32.1067L20.6663 34L29.9997 43.3334L39.333 34Z" fill="#222742" />
                  </svg>
                </div>
              </a>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Reports
